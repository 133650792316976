import React, { ReactNode } from 'react'
import { clsx, Container, Group, Stack, Card } from '@mantine/core'

import styles from './auth-card.module.scss'

export const AuthCard = ({ children }: { children: ReactNode }) => (
    <Container className="h-full" fluid>
        <Group className={clsx(styles.row, 'justify-center')}>
            <Stack className="px-0 w-5/6 lg:w-2/3">
                <div
                    className={`flex flex-col justify-center ${styles.wrapper}`}
                >
                    <Card className={`animated fadeIn border-0 ${styles.card}`}>
                        <section className="p-2">{children}</section>
                    </Card>
                </div>
            </Stack>
        </Group>
    </Container>
)
