import React from 'react'

import styles from './spinner-horizontal.module.scss'

interface ISpinnerHorizontal {
    className?: string
}

export const SpinnerHorizontal = ({ className = '' }: ISpinnerHorizontal) => (
    <div className={`${styles.ldsEllipsis} ${className}`}>
        <div />
        <div />
        <div />
        <div />
    </div>
)
