import { firebaseAppCtx } from '@jenni/common/src/firebase'
import { getRemoteConfig, getValue } from 'firebase/remote-config'
import { root } from 'lib/models/context'
import { useEffect } from 'react'

/**
 * Uses a remote config field from Firebase config.
 * Uses analytics to track the value of the flag.
 */
export const useFeatureFlag = (
    flagName: string,
    type: 'boolean' | 'number' = 'boolean'
) => {
    const flagObj =
        typeof window !== 'undefined'
            ? getValue(getRemoteConfig(firebaseAppCtx.getDefault()), flagName)
            : null

    const flagValue = flagObj
        ? type === 'boolean'
            ? flagObj.asBoolean()
            : flagObj.asNumber()
        : null

    useEffect(() => {
        if (root.auth.user)
            analytics.identify(root.auth.user.uid, {
                [flagName]: flagValue
            })
    }, [root.auth.user])

    return flagValue
}
