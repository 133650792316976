import React from 'react'

import styles from './spinner.module.scss'

interface ISpinner {
    size?: number
    editor?: boolean
    className?: string
}

export const Spinner = ({
    size = 30,
    editor = false,
    className = ''
}: ISpinner) => (
    <div
        className={`spinner inline-block relative ${
            !editor ? styles.ldsRing : styles.editorRing
        } ${className}`}
        style={{ width: size, height: size }}
    >
        <div />
        <div />
        <div />
        <div />
    </div>
)
