import { httpsCallable } from 'firebase/functions'
import { useStores } from 'lib/models/context'
import { functions } from 'lib/models/firebase'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useFeatureFlag } from './use-feature-flag'

export const useEditorFirst = () => {
    const { auth } = useStores()
    const router = useRouter()

    // AB flag for editor first
    const showEditorFirst = useFeatureFlag('test_editor_first')

    const createNewDoc = async () => {
        if (!auth.user) {
            return
        }

        // Set a one time flag to indicate first login
        localStorage.setItem(`first-new-doc-${auth.user.uid}`, 'true')
        try {
            const docResponse = await httpsCallable<any, any>(
                functions,
                'doc-createDoc'
            )({
                // Must pass something here to indicate it's not a warmup
                uid: auth.user.uid
            })

            const { docId } = docResponse.data
            await router.push({
                pathname: `/editor/${docId}`
            })
        } catch (error) {
            console.error(error)
            if (auth.user) {
                const { uid } = auth.user
                analytics.track('Create New Doc Error', {
                    userId: uid,
                    error: JSON.stringify(error)
                })
            }

            throw new Error('Error creating new document')
        }
    }

    useEffect(() => {
        if (auth.isAuthenticated && auth.user) {
            if (
                // Only show the editor first if the user is new and ab flag is true
                showEditorFirst &&
                localStorage.getItem(`first-new-doc-${auth.user.uid}`) !==
                    'true'
            ) {
                createNewDoc()
            } else {
                router.replace({ pathname: '/dashboard', query: router.query })
            }
        }
    })
}
