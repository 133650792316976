import React from 'react'
import { Container } from '@mantine/core'

import { Spinner } from '../common/spinner'

export const FullSpinner = () => (
    <Container
        className="dashboard min-h-screen bg-secondary-50 flex items-center justify-center px-5 full-spinner"
        fluid
    >
        <Spinner />
    </Container>
)
